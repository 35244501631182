import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Collection from "@components/partials/NFTsPage"

const ExplorerPage = () => (
    <Layout
        title="Mark McKenna's Heroes & Villains NFT"
        contentClassName="ExplorerPageContent px-0"
        type="explorer"
    >
        <Collection type="explorer"/>
    </Layout>
)

export default ExplorerPage
